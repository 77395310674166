import alert from "./alert";
import login from "./login";
import common from "./common";
import scanResult from "./scanResult";
import menu from "./menu";
import search from "./search";

const jaJP = {
  alert,
  common,
  login,
  scanResult,
  menu,
  search,
};

export default jaJP;
