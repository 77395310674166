const scanResult = {
  top: {
    filter_clear: "초기화",
    placeholder: "검색어를 입력하세요",
  },
  table: {
    header: {
      name: "제목",
      date: "날짜",
      domain: "도메인",
      title: "제목",
      tag: "태그",
      detection_info: "탐지 정보",
      resource_num: "리소스",
      link_num: "링크",
      country: "국가",
    },
    empty: "검색 결과가 없습니다.",
    loading: "데이터를 불러오고 있습니다. 잠시만 기다려주세요.",
    current_page_report:
      "전체 {totalRecords}개 중 {first} ~ {last}번째 데이터 출력",
  },
};

export default scanResult;
