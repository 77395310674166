import axios from "axios";
import { useAuthStore } from "@/store/auth";

export const RestClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

RestClient.interceptors.request.use(
  (reqConfig) => {
    const accessJwt = useAuthStore().accessJwt;
    if (accessJwt) {
      if (reqConfig.headers) {
        reqConfig.headers["X-ACCESS-TOKEN"] = accessJwt;
      }
    }
    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

RestClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      if (error?.config.method !== "put" && error?.config.url !== "/login") {
        const authStore = useAuthStore();
        await authStore.refreshAuthToken();
        return await resetTokenAndReattemptRequest(error);
      }
    }

    return Promise.reject(error);
  }
);

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

function onAccessTokenFetched(accessToken: string | null) {
  subscribers.forEach((callback) => {
    callback(accessToken);
  });
  subscribers = [];
}

async function resetTokenAndReattemptRequest(error: any) {
  try {
    const res = error?.response;
    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber(async (accessToken: string) => {
        try {
          res.config.headers = {
            ...res.config.headers,
            "X-ACCESS-TOKEN": accessToken,
          };
          resolve(RestClient(res.config));
        } catch (err) {
          reject(err);
        }
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const authStore = useAuthStore();
      const newAccessToken = await authStore.refreshAuthToken();
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newAccessToken);
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}
export interface RestError {
  error: string;
}

export const GetRestError = (e: unknown): [number, RestError] => {
  let status = 0;
  const restErr: RestError = { error: "" };
  if (axios.isAxiosError(e)) {
    if (e.response) {
      status = e.response.status;
      restErr.error = e.response.data;
    } else if (e.request) {
      status = 997;
      restErr.error = e.request;
    } else {
      status = 998;
      restErr.error = e.message;
    }
  } else {
    status = 999;
    restErr.error = e as string;
  }
  return [status, restErr];
};

export const GetError = (e: unknown): [number, string] => {
  let status = 0;
  let msg = "";
  if (axios.isAxiosError(e)) {
    if (e.response) {
      status = e.response.status;
      if (typeof e.response.data === "string") {
        msg = e.response.data;
      } else {
        msg = e.response.data.error;
      }
    } else if (e.request) {
      status = 997;
      msg = e.request as string;
    } else {
      status = 998;
      msg = e.message;
    }
  } else {
    status = 999;
    msg = e as string;
  }
  return [status, msg];
};
