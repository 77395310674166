const newsRoutes = [
  {
    path: "/news",
    name: "news",
    redirect: "/news/boannews", // /management로 접속 시 boannews로 리다이렉트
  },
  {
    path: "/news/boannews",
    name: "boannews",
    component: () =>
      import(/* webpackChunkName: "boannews" */ "@/views/news/NewsView.vue"),
  },
  {
    path: "/news/nurilab-blog",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "@/views/news/BlogView.vue"),
  },
  {
    path: "/news/:catchAll(.*)", // 잘못된 경로는 whiteList로 리다이렉트
    redirect: "/news/boannews",
  },
];

export default newsRoutes;
