import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/store/auth";

const loginRoutes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunckName: "login" */ "@/views/login/LoginView.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();
      // Redirect to dashboard if the user is authenticated (accessJwt exists)
      if (
        (to.name === "login" ||
          to.name === "forgot" ||
          to.path.startsWith("/password/reset/")) &&
        authStore.accessJwt
      ) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () =>
      import(
        /* webpackChunckName: "forgot" */ "@/views/login/ForgotPasswordView.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();

      // Redirect to dashboard if the user is authenticated (accessJwt exists)
      if (authStore.accessJwt) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/password/reset/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunckName: "reset-password" */ "@/views/login/ResetPasswordView.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();

      // Redirect to dashboard if the user is authenticated (accessJwt exists)
      if (authStore.accessJwt) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },
];

export default loginRoutes;
