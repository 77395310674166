const menu = {
  dashboard: "ダッシュボード",
  scanResult: "スキャン結果",
  management: "管理",
  account: "アカウント",
  support: "サポート",
  dashboard1: "ダッシュボード 1",
  dashboard2: "ダッシュボード 2",
  whiteList: "ホワイトリスト",
  blackList: "ブラックリスト",
  permutation: "パーミュテーション",
  pageTitle: "ページタイトル",
  pattern: "パターン",
  report: "報告",
  contact: "お問い合わせ",
  mypage: "マイページ",
  logout: "ログアウト",
  audit: "監査 ログ",
  securityNews: "セキュリティ ニュース",
  news: "ニュース",
  blog: "ブログ",
};

export default menu;
