const managementRoutes = [
  {
    path: "/management",
    redirect: "/management/whiteList", // /management로 접속 시 whiteList로 리다이렉트
  },
  {
    path: "/management/whiteList",
    name: "whiteList",
    component: () =>
      import(
        /* webpackChunkName: "whiteList" */ "@/views/management/WhiteListView.vue"
      ),
  },
  {
    path: "/management/blackList",
    name: "blackList",
    component: () =>
      import(
        /* webpackChunkName: "blackList" */ "@/views/management/BlackListView.vue"
      ),
  },
  {
    path: "/management/pattern",
    name: "pattern",
    component: () =>
      import(
        /* webpackChunkName: "pattern" */ "@/views/management/PatternView.vue"
      ),
  },
  {
    path: "/management/permutation",
    name: "permutation",
    component: () =>
      import(
        /* webpackChunkName: "permutation" */ "@/views/management/PermutationView.vue"
      ),
  },
  {
    path: "/management/:catchAll(.*)", // 잘못된 경로는 whiteList로 리다이렉트
    redirect: "/management/whiteList",
  },
];

export default managementRoutes;
