const auditRoutes = [
  {
    path: "/audit",
    name: "audit",
    component: () =>
      import(/* webpackChunckName: "support" */ "@/views/audit/auditView.vue"),
  },
];

export default auditRoutes;
