const scanResultsRoutes = [
  {
    path: "/scanresult",
    name: "scanresult",
    component: () =>
      import(
        /* webpackChunckName: "scanresult" */ "@/views/scanResult/ScanResultsView.vue"
      ),
  },
  {
    path: "/scanresult/:id",
    name: "scanresult-summary",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "/scanresult/:id/resources",
    name: "scanresult-resources",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "/scanresult/:id/links",
    name: "scanresult-links",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
];

export default scanResultsRoutes;
