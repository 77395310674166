const scanResult = {
  top: {
    filter_clear: "Clear",
    placeholder: "Keyword Search",
  },
  table: {
    header: {
      name: "Name",
      date: "Date",
      domain: "Domain",
      title: "Title",
      tag: "Tag",
      detection_info: "Detection Info",
      resource_num: "Resources",
      link_num: "Links",
      country: "Country",
    },
    empty: "No search results.",
    loading: "Loading data. Please wait.",
    current_page_report: "Showing {first} to {last} of {totalRecords}",
  },
};

export default scanResult;
